$stripe-size: 100px;
$color1: rgba(0, 0, 0, 0.3);
$color2: rgba(0, 0, 0, 0.1);
$duration: 2s;

.stripe {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s ease-out;
  pointer-events: none;

  &.stripe-visible {
    opacity: 0.4;
    pointer-events: all;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + #{$stripe-size});
    height: 100%;
    background: repeating-linear-gradient(
      45deg,
      $color2 25%,
      $color2 50%,
      $color1 50%,
      $color1 75%
    );
    background-size: $stripe-size $stripe-size;
    animation: stripeTransform $duration linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      ellipse at center,
      rgba(#1b2735, 0) 0%,
      #090a0f 100%
    );
  }
}

@keyframes stripeTransform {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(#{$stripe-size} * -1));
  }
}

@keyframes stripeBackgroundPosition {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: calc(#{$stripe-size} * -1) 0;
  }
}

// This class will apply an animated outline around the element.
// It uses an absolutely positioned pseudo element animating the
// top, right, bottom and left properties between 0 and 15px.
.pulse {
  // Keeps the pseudo elmement positioned relative to the actual element.
  position: relative;

  &:before {
    // Needed for the pseudo element to appear.
    content: "";
    // Ensures the pulse appears above surrounding content.
    z-index: 100;
    // outlinePulse animates the top, right, bottom and left properties.
    position: absolute;

    // This allows the pulse style to be determined
    // by the element .pulse is applied to.
    border: inherit;
    border-radius: inherit;

    // Animation params
    animation-duration: 1250ms;
    animation-name: outlinePulse;
    animation-iteration-count: infinite;
    animation-direction: normal;
  }

  @keyframes outlinePulse {
    from {
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 1;
    }

    to {
      top: -15px;
      left: -15px;
      right: -15px;
      bottom: -15px;
      opacity: 0;
    }
  }
}
