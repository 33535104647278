//
// Typography
$font-family-sans-serif:
  ProximaNova,
  "Open Sans",
  -apple-system,
  BlinkMacSystemFont,
  "Helvetica Neue",
  Arial,
  "Noto Sans",
  sans-serif;

$font-size-base: 0.9rem;
$font-size-lg: $font-size-base * 1;
$font-size-sm: $font-size-base * 0.8;

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1;
$h6-font-size: $font-size-base;

$input-font-size: $font-size-base;

//
// Colors
$custom-theme-colors: (
  "dark": #2d2d2d,
);
$custom-border-color: rgba(0, 0, 0, 0.6);

//
// Forms
$input-placeholder-color: #a7a7a7;
$form-check-input-border: var(--bs-border-width) solid $custom-border-color;

//
// Navigation
$nav-link-padding-y: 0.65rem;
$nav-link-padding-x: 1rem;
$nav-tabs-border-width: 1px;

//
// Custom stuff
$sidebar-width: 240px;
$sidebar-width-mobile: 48px;
